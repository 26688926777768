<template>
  <form-field :name="field.name">
    <v-text-field
      v-model="field.value"
      :loading="loading"
      :prepend-icon="prependIcon"
      v-bind="binding"
      clearable
      box
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'
import { createGetParams } from '@/rest'

export default {
  mixins: [formField],
  data () {
    return {
      hint: '',
      loading: false,
      longMask: '#### ### ####',
      shortMask: '### ### ####',
      source: null,
    }
  },
  computed: {
    binding: function () {
      return Object.assign(this.field.props, {
        mask: this.mask,
        counter: this.counter,
      })
    },
    counter: function () {
      return this.mask === this.longMask ? 11 : 10
    },
    input: function () {
      return this.field.value
    },
    mask: function () {
      if (!this.input) {
        return this.shortMask
      }

      const mask = this.input.startsWith('011') ? this.longMask : this.input.startsWith('01') ? this.shortMask
        : (!this.input.startsWith('0') || (!this.input.startsWith('01') && this.input.length > 1))
          ? '01# ### ####' : this.shortMask
      return mask
    },
    prependIcon: function () {
      return this.field.props.ugu ? 'phonelink_ring' : 'mdi-cellphone'
    },
  },
  watch: {
    input: function (val) {
      this.cancelRequest()
      if (val && ((this.mask === this.longMask && val.length === 11) ||
          (this.mask === this.shortMask && val.length === 10))) {
        this.verifyMsisdn()
      } else {
        this.validate()
        this.field.props.hint = this.hint
        this.loading = false
      }
    },
  },
  mounted: function () {
    this.field.props.rules.push(val => this.validateLength(val))
    if (this.hint.length === 0) {
      this.hint = this.field.props.hint === undefined || this.field.props.hint === null ? '' : this.field.props.hint
    }
  },
  methods: {
    cancelRequest: function () {
      this.validate()
      if (this.source) {
        this.source.cancel('Operation canceled by the user.')
        this.source = null
      }
    },
    validateLength: function (val) {
      if (!val) return true
      return ((val.startsWith('011') && val.length < 11) || val.length < 10) ? 'Invalid Msisdn!' : true
    },
    verifyMsisdn: function () {
      const url = this.field.props.searchNetwork ? 'getProfileFromNetwork2.php' : 'getProfile.php'
      const params = createGetParams({
        target: this.input,
      })
      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.loading = true
      this.$rest.get(url, params)
        .then(response => {
          if (response.data.msisdn) {
            if (this.field.props.existing) {
              if (this.field.props.noDealer && response.data.dealer_status === 'active') {
                this.validate('Dealer Msisdn not allowed!')
              } else if (this.field.props.noNuDealer) {
                if (response.data.dealer_status === 'active' && response.data.dealer) {
                  if (response.data.dealer.startsWith('NU')) {
                    this.validate('Subscriber is NU dealer!')
                  } else if (response.data.nu) {
                    this.validate('Dealer has existing NU portfolio: ' + response.data.nu)
                  }
                  this.field.props.hint = response.data.dealer_status === 'active' ? response.data.dealer + ': ' : ''
                  this.field.props.hint += response.data.name
                } else if (response.data.black !== 'NU') {
                  this.validate('Subscription is not NU!')
                } else {
                  this.field.props.hint = response.data.name
                }
              } else if (this.field.props.noSelf && response.data.self) {
                this.validate('Self Msisdn not allowed!')
              } else if (this.field.props.noBlack && response.data.black) {
                this.validate('Present account is Black!')
              } else if (this.field.props.noPrepaid && !response.data.black) {
                this.validate('Present account is prepaid!')
              } else if (this.field.props.activeOnly && response.data.lineStatus != null &&
                  Number(response.data.lineStatus) !== 1) {
                const lineStatus = Number(response.data.lineStatus)
                switch (lineStatus) {
                  case 0:
                  case 4:
                    this.validate('Line terminated!')
                    break
                  case 2:
                    this.validate('Line barred!')
                    break
                  case 3:
                    this.validate('Line blocked!')
                    break
                  default:
                    this.validate('Line unknown!')
                    break
                }
              } else {
                this.field.props.hint = response.data.dealer_status === 'active' ? response.data.dealer + ': ' : ''
                this.field.props.hint += response.data.name
              }
            } else {
              this.validate('Msisdn exists in OneSYS!')
            } // else Success
          } else {
            this.validate('Unexpected response!')
          }
          this.loading = false
        }).catch(error => {
          if (this.$rest.isCancel(error)) {
            // ignore
          } else if (error.response) {
            if (error.response.status === 404) {
              if (this.field.props.existing) {
                this.validate('No such Msisdn!')
              } // else Success
            } else {
              this.validate(error.message)
            }
          } else {
            this.validate(error.message)
          }
          this.loading = false
        })
    },
  },
}
</script>
